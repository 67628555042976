import { EnsureModuleLoadedOnceGuard } from '@core/guards/ensureModuleLoadedOnceGuard';
import { NgModule, Optional } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SkipSelf } from '@angular/core';
import { NavBarComponent } from '@core/nav-bar/nav-bar.component';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    NavBarComponent
  ],
  imports: [
    CommonModule,
    RouterModule
  ],
  exports: [
    NavBarComponent
  ]
})
export class CoreModule extends EnsureModuleLoadedOnceGuard {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    super(parentModule);
  }
}